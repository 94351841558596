import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/blog.scss"

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  const { title, description, date, timetoread } = post.frontmatter
  return (
    <Layout>
      <SEO title={title} />
      <div>
        <h1 className="blog__title">{title}</h1>
        <div className="blog__info">
          <p className="blog__date">{date}</p>
          <p className="blog__bullet">&bull;</p>
          <p className="blog__timetoread">{timetoread} minutes to read</p>
        </div>
        {description && <p className="blog__description">{description}</p>}
        <hr></hr>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <footer>© 2018-{new Date().getFullYear()} Erik Niehaus</footer>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        description
        timetoread
      }
    }
  }
`
